#toast {
    z-index: 100;
    position: absolute;
}

.navbar {
    border-bottom: 1px solid #c4c4c4;
    background-image: linear-gradient(white, #363636);
    font-weight: bolder;
}

#home {
    text-align: center;
}

hr {
    background-color: #6b6b6b;
}

.cartDivider {
    background-color: #6b6b6b;
    width: 100%;
    margin: 0 auto;
}

.navbar-brand {
    font-family: 'Black Ops One';
}

#nav-header {
    position:relative;
    left: -70px;
}

#nav-footer {
    position:relative;
    left: -80px;
}

.main-button {
    font-weight: bold;
    border: 2px solid black;
    padding: 3px 20px;
    border-radius: 25px;
}

.removeFromCartButton:hover {
    color:rgb(61, 0, 0);
}

#carousel {
    width: 100%;
    max-width:600px;
    max-height: 800px;
}

Card { 
    height: 100%
}

ul > li {
    text-align: left;
}

.pc-price {
    color: #00c932;
    font-weight: bold;
}

footer {
    border-top: 1px solid black;
    background-image: linear-gradient(white, #363636);
}

#footer-div {
    display: inline;
}

#footer-list li {
    display:inline;
    margin-left:1.6rem;
    
}

#adviceForm {
    border: 1px solid black;
    padding: 3%;
    border-radius: 25px;
}

#daysAvailable {
    display: inline;
}

@media screen and (max-width: 850px) {
    #nav-header {
        position:relative;
        left: -40px;
    }
    
    #nav-footer {
        position:relative;
        left: -50px;
    }
}

@media screen and (max-width: 766.98px) {
    #nav-header {
        position:relative;
        left: 0;
    }
    
    #nav-footer {
        position:relative;
        left: 0;
    }
}
